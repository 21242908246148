//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import Home from './Home';
import { getAllApplications } from '../Redux/applicationsSelectors';
import { getAllContents } from '../Redux/contentSelectors';
import { getAllCompanies } from '../Redux/companiesSelectors';
import { getAuthUser } from '../Redux/authSelectors';
import { setApplicationRequest, updatePaidRequest } from '../Redux/applicationsReducer';
import { getMyCompony } from '../Redux/mycompanySelectors';
import { updateApplicationRequest, deleteApplicationRequest, updateAVRRequest, updateEsfRequest, updateEsfDoneRequest } from '../Redux/applicationsReducer'

class HomeContainer extends React.PureComponent {

    render() {
        return (<>
            <div style={{maxWidth: "412px", margin: "auto"}} >
                <Home
                    allApplications={this.props.allApplications}
                    allContents={this.props.allContents}
                    allCompanies={this.props.allCompanies}
                    authUser={this.props.authUser}
                    setApplicationRequest={this.props.setApplicationRequest}
                    myCompony={this.props.myCompony}                    
                    updateApplicationRequest={this.props.updateApplicationRequest}
                    deleteApplicationRequest={this.props.deleteApplicationRequest}
                    updatePaidRequest={this.props.updatePaidRequest}
                    updateAVRRequest={this.props.updateAVRRequest}
                    updateEsfRequest={this.props.updateEsfRequest}
                    updateEsfDoneRequest={this.props.updateEsfDoneRequest}
                />
            </div>
        </>)
    }
}

const mapStateToProps = (state) => {
    return {
        allApplications: getAllApplications(state),
        allContents: getAllContents(state),
        allCompanies: getAllCompanies(state),
        authUser: getAuthUser(state),
        myCompony: getMyCompony(state),
    }
};

export default connect(mapStateToProps, { 
    setApplicationRequest,          
    updateApplicationRequest,
    deleteApplicationRequest,
    updatePaidRequest,
    updateAVRRequest,
    updateEsfRequest,
    updateEsfDoneRequest
})(HomeContainer)
