import * as React from 'react';
import SideBar from './components/SideBar';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import OrdersContainer from './Panels/OrdersContainer';
import UserProfilePanelContainer from './Panels/Users/UserProfilePanelContainer';
import CompanyProfilePanelContainer from './Panels/Reference/CompanyProfilePanelContainer';
import ProductPanelContainer from './Panels/Reference/ProductPanelContainer';
import ClientsPanelContainer from './Panels/Reference/ClientsPanelContainer';
import ClientsAdressPanelContainer from './Panels/Reference/ClientsAdressPanelContainer';
import BuhChatContainer from './Panels/Chat/BuhChatContainer';
import { useEffect } from 'react';
import TableAdressCompany from '../Components/TableAdressCompany';
import UsersPanelContainer from './Panels/Users/Users/UsersPanelContainer';
import FNOReportsPanelContainer from './Panels/FNOReports/FNOReportsPanelContainer';
import ExpensesContainer from '../Expenses/ExpensesContainer';
import dayjs from 'dayjs';
import DashboardPanelContainer from './Panels/DashboardPanelContainer';
import TaskManagerContainer from './Panels/TaskManager/TaskManagerContainer';
import ShopOrdersContainer from './Panels/ShopOrders/ShopOrdersContainer';

const Dashboard = ({
    getCompaniesRequest,
    contentsRequest,
    getApplicationRequest,
    getAddrDeliveryRequest,
    getOldApplicationRequest,
    getAllWagesRequst,
    getAllExpensGroupsRequst,
    getAllExpensesRequest,
    getAllFNO910Requst,
    pointsRequest,
    getAllFNO913Requst,
    chatsRequest,
    getUsersRequst,
    pointsUpdate,
    getALLTaskRequest,
}) => {

    const nowDate = dayjs(new Date())
    const time = (nowDate.$H > 9 ? nowDate.$H : '0' + nowDate.$H) + ':' + (nowDate.$m > 9 ? nowDate.$m : '0' + nowDate.$m)
    const date = nowDate.$D + '.' + (nowDate.$M + 1 > 9 ? nowDate.$M + 1 : '0' + (nowDate.$M + 1)) + '.' + nowDate.$y
    console.log(time);
    console.log(date);

    const [mobileOpen, setMobileOpen] = useState(false);
    const [dataCloseTask, setDataCloseTask] = useState(date);
    const words = useSelector(state => state.language.words)
    const myCompany = useSelector(state => state.myCompany)
    const authUser = useSelector(state => state.auth.authUser)

    const chats = useSelector(state => state.chat.chats_id)
    const allCompany = useSelector(state => state.myCompany.allCompany)

    const [selectCompany, setSelectCompany] = useState(allCompany.length > 0 ? allCompany[0] : myCompany)
    // const chat_users = useSelector(state => state.chat.chat_users)
    // const chat_messeges = useSelector(state => state.chat.chat_messeges)
    // const status_messeges = useSelector(state => state.chat.status_chat_messeges)
    // const allAppl = useSelector(state => state.applicaions.allApplications)
    const [Component, setComponent] = useState({ title: words.dashboard, content: <DashboardPanelContainer /> })

    const navigate = useNavigate()

    useEffect(() => {
        setSelectCompany(allCompany.length > 0 ? allCompany[0] : myCompany)
    }, [allCompany, myCompany, setSelectCompany])

    const downloadData = (id) => {
        getUsersRequst({ users: myCompany.allCompany.filter((comp) => comp.id === id[0])[0].users })
        getCompaniesRequest({ mycompany: id })
        contentsRequest({ mycompany: id })
        getApplicationRequest({ mycompany: id })
        getAddrDeliveryRequest({ mycompany: id })
        getOldApplicationRequest({ mycompany: id })
        getAllWagesRequst({ mycompany: id })
        getAllExpensGroupsRequst({ mycompany: [0, ...id] })
        getAllExpensesRequest({ mycompany: id })
        getAllFNO910Requst({ mycompany: id })
        pointsRequest({ mycompany: id })
        getAllFNO913Requst({ mycompany: id })
        chatsRequest({ mycompany: id })
        setSelectCompany(allCompany.filter((c) => c.id === id[0])[0])
    }

    useEffect(() => {
        let timer
        if (chats.length > 0) {
            timer = setInterval(() => {
                chatsRequest({ mycompany: [selectCompany.id] })
            }, 10000);
        }
        // очистка интервала
        return () => clearInterval(timer);
    });

    useEffect(() => {
        if (authUser.id !== 0) {
            getALLTaskRequest({ idUser: authUser.id })
        }
    }, [authUser.id, getALLTaskRequest, dataCloseTask])


    const setPointCompany = (obj, idCompany) => {
        const newObj = {
            newPoint: (obj.newPoint.length > 0 ? obj.newPoint.map((p) => ({ ...p, myCompanyId: idCompany })) : obj.newPoint),
            updatePoint: (obj.updatePoint.length > 0 ? obj.updatePoint.map((p) => ({ ...p, myCompanyId: idCompany })) : obj.updatePoint)
        }
        pointsUpdate(newObj, idCompany)
    }

    const setContent = (index) => {

        switch (index) {
            case 0:
                return setComponent({ title: words.dashboard, content: <DashboardPanelContainer /> });

            case 1:
                return setComponent({ title: words.orders, content: <OrdersContainer selectCompany={selectCompany} /> });

            case 2:
                return setComponent({ title: words.expenses, content: <ExpensesContainer myCompany={selectCompany} /> });

            case 3:
                return setComponent({ title: words.shopOrders, content: <ShopOrdersContainer /> });

            case 4:
                return setComponent({ title: words.chats, content: <BuhChatContainer /> });

            case 5:
                return setComponent({ title: words.taskManager, content: <TaskManagerContainer /> });

            case 21:
                return setComponent({ title: words.usersSettings, content: <UsersPanelContainer /> });

            case 22:
                return setComponent({ title: words.userProfileSettings, content: <UserProfilePanelContainer /> });

            case 31:
                return setComponent({ title: words.companyProfileSettings, content: <CompanyProfilePanelContainer selectCompany={selectCompany} /> });

            case 32:
                return setComponent({ title: words.productProfileSettings, content: <ProductPanelContainer myCompany={selectCompany} /> });

            case 33:
                return setComponent({ title: words.clientsProfileSettings, content: <ClientsPanelContainer myCompany={selectCompany} /> });

            case 34:
                return setComponent({ title: words.clientsAddressProfileSettings, content: <ClientsAdressPanelContainer myCompany={selectCompany} /> });

            case 35:
                return setComponent({ title: words.pointsSettings, content: <TableAdressCompany pointsUpdate={setPointCompany} idMyCompany={selectCompany.id} /> });

            case 41:
                return setComponent({ title: words.fnoReports, content: <FNOReportsPanelContainer selectCompany={selectCompany} /> });

            default:
                return setComponent(<DashboardPanelContainer />);
        }
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    if (myCompany.bin.length === 0 || authUser.id === 0) {
        navigate('/')
        return <></>
    } else {
        return (
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>

                <IconButton
                    color="inherit"
                    aria-label="open drawer"

                    onClick={handleDrawerToggle}
                    sx={{ mr: 1, top: '16px', height: '32px', width: '32px', left: '18px', border: 1, borderColor: '#636b74', borderRadius: '8px', p: '5px', display: { sm: 'none' } }}
                >
                    <MenuIcon sx={{ width: '20px', height: '20px', color: '#636b74' }} />
                </IconButton>

                <SideBar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} setContent={setContent} words={words} myCompany={myCompany} authUser={authUser} downloadData={downloadData} />

                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3 }}
                >
                    <Typography variant='h2' fontSize={30} fontWeight={700} sx={{ lineHeight: '1.33', letterSpacing: '-0.025em' }}>
                        {Component.title}
                    </Typography>
                    <Divider sx={{ borderColor: '#fcbb42' }} />
                    {Component.content}
                </Box>

            </Box>)
    }
}

export default Dashboard