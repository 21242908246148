import * as React from 'react';
import { useSelector } from 'react-redux';
import UserCard from '../../../components/UserCard';
import { Grid } from '@mui/material';

const UsersPanel = ({ updateAuthUserRequest, updateUserPwdRequest }) => {
    //const myCompany = useSelector(state => state.myCompany)
    const users = useSelector(state => state.users.users)
    const authUser = useSelector(state => state.auth.authUser)
    let usersCard = []
    console.log(authUser);
    console.log(users);
    
    if (authUser.id === 21 || authUser.id === 69) {
        usersCard = users.map((u) => <UserCard key={u.id} user={u} updateAuthUserRequest={updateAuthUserRequest} updateUserPwdRequest={updateUserPwdRequest} />)
    } else {         
        usersCard = users.filter((u)=> {
            return u.id === 21 || u.id === 69? false : true             
        })
        usersCard =usersCard.map((u) => <UserCard key={u.id} user={u} updateAuthUserRequest={updateAuthUserRequest} updateUserPwdRequest={updateUserPwdRequest} />)
    }
        

    return (<div style={{ marginTop: '15px' }}>
        <Grid container spacing={2} >
            {usersCard}
        </Grid>
    </div>)
}

export default UsersPanel