import ConnectToServer from "../API/ConnectToServer";

//Action type
const SET_ALL_PRODUCTS = 'SHOP/SET_ALL_PRODUCTS';
const SET_PRODUCTS_ORDERS_CLIENT = 'SHOP/SET_PRODUCTS_ORDERS_CLIENT';
const SET_ORDERS_CLIENT = 'SHOP/SET_ORDERS_CLIENT';
const SET_PRODUCTS_ORDERS_SHOP = 'SHOP/SET_PRODUCTS_ORDERS_SHOP';
const SET_ORDERS_SHOP = 'SHOP/SET_ORDERS_SHOP';
const SET_ALL_PRODUCT_GROUPS = 'SHOP/SET_ALL_PRODUCT_GROUPS';
const SET_IN_BASKET = 'SHOP/SET_IN_BASKET';

//Started props
let initialState = {
    isFetching: false,
    allProducts: [],
    groupsProduct: [],
    productOrdersClients: [],
    ordersClients: [],
    productOrdersShop: [],
    ordersShopShop: [],
    masterBasket: [],
    productOrdersShop: [],
    ordersShop: [],
};

//Reducers functions
const shopReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_ALL_PRODUCTS:
            return {
                ...state,
                allProducts: action.shopProducts
            }

        case SET_ALL_PRODUCT_GROUPS:
            return {
                ...state,
                groupsProduct: action.shopGroups
            }

        case SET_IN_BASKET:
            return {
                ...state,
                masterBasket: action.products
            }

        case SET_PRODUCTS_ORDERS_CLIENT:
            return {
                ...state,
                productOrdersClients: action.orderProducts
            }

        case SET_ORDERS_CLIENT:
            return {
                ...state,
                ordersClients: action.orders
            }

        case SET_PRODUCTS_ORDERS_SHOP:
            return {
                ...state,
                productOrdersShop: action.orderProducts
            }

        case SET_ORDERS_SHOP:
            return {
                ...state,
                ordersShopShop: action.orders
            }

        default:
            return state;
    }
}

//Action Creators functions
const setProducts = (shopProducts) => {
    return { type: SET_ALL_PRODUCTS, shopProducts }
}

const setGroups = (shopGroups) => {
    return { type: SET_ALL_PRODUCT_GROUPS, shopGroups }
}

const setClientOrders = (orders) => {
    return { type: SET_ORDERS_CLIENT, orders }
}

const setClientOrderProducts = (orderProducts) => {
    return { type: SET_PRODUCTS_ORDERS_CLIENT, orderProducts }
}

const setShopOrders = (orders) => {
    return { type: SET_ORDERS_SHOP, orders }
}

const setShopOrderProducts = (orderProducts) => {
    return { type: SET_PRODUCTS_ORDERS_SHOP, orderProducts }
}

//Thunk functions
export const shopProductsRequest = (obj, idGroupe) => {
    return async (dispatch) => {
        const data = await ConnectToServer.getALLShopProducts(obj)
        if (data.success === 1) {
            console.log(idGroupe);
            if (idGroupe[0] === 0) {dispatch(setProducts(data.shopProducts.filter((prod) => prod.closeGroupe===0)))} else {
                dispatch(setProducts(data.shopProducts.filter((prod) => prod.closeGroupe===0 || idGroupe.includes(prod.closeGroupe))))
            }
        } else {
            console.log(data.message)
            dispatch(setProducts([]))
            //dispatch(toggleIsFetching(false))
        }
    }
}

export const shopGroupsRequest = (obj, idGroupe) => {
    return async (dispatch) => {
        const data = await ConnectToServer.getALLShopGroups(obj)
        if (data.success === 1) {
            console.log(idGroupe);
            if (idGroupe[0] === 0) {dispatch(setGroups(data.shopGroups.filter((prod) => prod.closeGroupe===0)))} else {
                dispatch(setGroups(data.shopGroups.filter((prod) => prod.closeGroupe===0 || idGroupe.includes(prod.closeGroupe)))) }
            
        } else {
            console.log(data.message)
            dispatch(setGroups([]))
            //dispatch(toggleIsFetching(false))
        }
    }
}

export const getAllOrdersClientsRequest = (obj) => {
    return async (dispatch) => {
        const data = await ConnectToServer.getALLShopOrdersClients(obj)
        if (data.success === 1) {
            dispatch(setClientOrders(data.orders))
            dispatch(setClientOrderProducts(data.orderProducts))
        } else {
            console.log(data.message)
            //dispatch(setGroups([]))
            //dispatch(toggleIsFetching(false))
        }
    }
}

export const getAllOrdersShopRequest = (obj) => {
    return async (dispatch) => {
        const data = await ConnectToServer.getALLShopOrders(obj)
        if (data.success === 1) {
            dispatch(setShopOrders(data.orders))
            dispatch(setShopOrderProducts(data.orderProducts))
        } else {
            console.log(data.message)
            //dispatch(setGroups([]))
            //dispatch(toggleIsFetching(false))
        }
    }
}

export const addNewOrdersShopRequest = (obj) => {
    return async (dispatch) => {
        const data = await ConnectToServer.setNewOrdersShop(obj)
        if (data.success === 1) {
            alert('Заказ #' + data.order + ' оформлен!')
            dispatch(getAllOrdersClientsRequest({ idUser: obj.idUser }))
        } else {
            console.log(data.message)
            //dispatch(setGroups([]))
            //dispatch(toggleIsFetching(false))
        }
    }
}

export default shopReducer;