import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import * as React from 'react';
//import seal from '../../../Img/seal.png'
//import signature from '../../../Img/signature.png'

const SignatureTableAVR = ({myCompony}) => {
    const styles = StyleSheet.create({
        table: {
            display: "table",
            width: "auto",
            marginTop: '5px'
        },
        seal: {
            position: 'absolute',
            top: '-50px',
            left: '-200px',
            width: '128px',
        },
        signature: {
            position: 'absolute',
            top: '-10px',
            left: '-5px',
            width: '100px',
        },
        tableRow: {
            flexDirection: "row"
        },
        tableRowTop: {
            flexDirection: "row",
            marginTop: '10px'
        },
        tableRowTop20: {
            flexDirection: "row",
            marginTop: '20px'
        },
        tableRowBot: {
            flexDirection: "row",
            marginBottom: '10px'
        },
        tableCol35: {
            width: "35%",
        },
        tableCol65: {
            width: "65%",
        },
        tableCol7: {
            width: "7%",
        },
        tableCol23: {
            width: "23%",
            paddingLeft: '3px'
        },
        tableCol25: {
            width: "25%",
        },
        tableCol70: {
            width: "70%",
        },
        tableCol50: {
            width: "50%",
            paddingRight: '20px'
        },
        tableCol50Right: {
            width: "50%",
            paddingLeft: '20px'
        },
        tableCol24: {
            width: "24%",
        },
        tableCol49: {
            width: "49%",
        },
        tableCol26: {
            width: "26%",
        },
        tableCol21: {
            width: "21%",
        },
        tableCol2: {
            width: "2%",
        },
        tableCol30: {
            width: "30%",
        },
        tableCell: {
            width: '100%',
            textAlign: 'left',
            fontSize: 10,
        },
        tableCellDelivery: {
            width: '100%',
            textAlign: 'left',
            fontSize: 10,
            fontWeight: 'bold'
        },
        tableCellMP: {
            width: '100%',
            paddingTop: '5px',
            textAlign: 'left',
            fontSize: 10,            
            fontWeight: 'bold',
            paddingLeft: '15px'
        },
        tableCellItaly: {
            width: '100%',
            textAlign: 'center',
            fontSize: 10,
            margin: 'auto',
            fontStyle: 'Italy',
        },
        tableCellLine: {
            width: '100%',
            fontSize: 10,
            borderBottom: 1
        },
        tableCell7: {
            width: '100%',
            textAlign: 'left',
            fontSize: 7,
            margin: 'auto',
            paddingLeft: '2px'
        },
    })



    return (
        <View style={styles.table}>
            {/* итого */}
            <View style={styles.tableRowTop}>
                <View style={styles.tableCol35}>
                    <Text style={styles.tableCell}>Сведения об использовании запасов, полученных от заказчика</Text>
                </View>
                <View style={styles.tableCol65}>
                    <Text style={styles.tableCellLine}> </Text>
                </View>
            </View>
            <View style={styles.tableRowBot}>
                <View style={styles.tableCol35}>
                </View>
                <View style={styles.tableCol65}>
                    <Text style={styles.tableCellItaly}>наименование, количество, стоимость</Text>
                </View>
            </View>
            <Text style={styles.tableCell}>Приложение: Перечень документации, в том числе отчет(ы) о маркетинговых, научных исследованиях, консультационных и прочих услугах (обязательны при его</Text>

            <View style={styles.tableRow}>
                <View style={styles.tableCol7}>
                </View>
                <View style={styles.tableCol23}>
                    <Text style={styles.tableCell}> (их) наличии) на _____________ страниц </Text>
                </View>
                <View style={styles.tableCol70}>
                    <Text style={styles.tableCellLine}> </Text>
                </View>
            </View>

            <View style={styles.tableRowTop20}>
                <View style={styles.tableCol50}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol24}>
                            <Text style={styles.tableCell}>Сдал (Исполнитель)</Text>
                        </View>
                        <View style={styles.tableCol21}>
                            <Text style={styles.tableCellLine}>{myCompony.sposition.length === 0? "Директор":myCompony.sposition}</Text>
                        </View>
                        <View style={styles.tableCol2}>
                            <Text style={styles.tableCell7}>/</Text>
                        </View>
                        <View style={styles.tableCol21}>
                            <Text style={styles.tableCellLine}> </Text>
                            {/* {myCompony.seal.length ===0? <View></View>: <Image style={styles.seal} src={myCompony.seal} />} 
                            {myCompony.signature.length ===0? <View></View>: <Image style={styles.signature} src={myCompony.signature} />}                              */}
                        </View>
                        <View style={styles.tableCol2}>
                            <Text style={styles.tableCell7}>/</Text>
                        </View>
                        <View style={styles.tableCol30}>
                            <Text style={styles.tableCellLine}>{myCompony.signatory.length === 0? myCompony.director:myCompony.signatory}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol24}>                            
                        </View>
                        <View style={styles.tableCol21}>
                            <Text style={styles.tableCellItaly}>должность</Text>
                        </View>
                        <View style={styles.tableCol2}>
                        </View>
                        <View style={styles.tableCol21}>
                            <Text style={styles.tableCellItaly}>подпись</Text>
                        </View>
                        <View style={styles.tableCol2}>
                        </View>
                        <View style={styles.tableCol30}>
                            <Text style={styles.tableCellItaly}>расшифровка подписи</Text>
                        </View>
                    </View>
                    <Text style={styles.tableCellMP}>М.П.</Text>
                </View>
                <View style={styles.tableCol50Right}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol24}>
                            <Text style={styles.tableCell}>Принял (Заказчик)</Text>
                        </View>
                        <View style={styles.tableCol21}>
                            <Text style={styles.tableCellLine}> </Text>
                        </View>
                        <View style={styles.tableCol2}>
                            <Text style={styles.tableCell7}>/</Text>
                        </View>
                        <View style={styles.tableCol21}>
                            <Text style={styles.tableCellLine}> </Text>
                        </View>
                        <View style={styles.tableCol2}>
                            <Text style={styles.tableCell7}>/</Text>
                        </View>
                        <View style={styles.tableCol30}>
                            <Text style={styles.tableCellLine}> </Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol24}>                            
                        </View>
                        <View style={styles.tableCol21}>
                            <Text style={styles.tableCellItaly}>должность</Text>
                        </View>
                        <View style={styles.tableCol2}>
                        </View>
                        <View style={styles.tableCol21}>
                            <Text style={styles.tableCellItaly}>подпись</Text>
                        </View>
                        <View style={styles.tableCol2}>
                        </View>
                        <View style={styles.tableCol30}>
                            <Text style={styles.tableCellItaly}>расшифровка подписи</Text>
                        </View>
                    </View>
                    <View style={styles.tableRowTop}>
                        <View style={styles.tableCol49}>
                            <Text style={styles.tableCell}>Дата подписания (принятия) работ (услуг)</Text>
                        </View>                        
                        <View style={styles.tableCol25}>
                            <Text style={styles.tableCellLine}> </Text>
                        </View>
                        <View style={styles.tableCol26}>                            
                        </View>
                    </View>     
                    <Text style={styles.tableCellMP}>М.П.</Text>               
                </View>
            </View>
        </View>
    );
}

export default SignatureTableAVR;