import React, { useCallback, useEffect, useState } from 'react';
import { Backdrop, CircularProgress, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Font } from '@react-pdf/renderer';
import Calibri from '../Fonts/calibri.ttf'
import CalibriBold from '../Fonts/calibrib.ttf'
import CalibriItaly from '../Fonts/calibrii.ttf'
import CalibriIB from '../Fonts/calibriz.ttf'
import { useNavigate } from 'react-router-dom';

const MainApp = ({
    authUser,
    authUserRequest,
    myCompanyRequest,
    getCompaniesRequest,
    contentsRequest,
    getApplicationRequest,
    getAddrDeliveryRequest,
    getOldApplicationRequest,
    getAllWagesRequst,
    getAllExpensGroupsRequst,
    getAllExpensesRequest,
    getAllFNO910Requst,
    pointsRequest,
    getAllFNO913Requst,
    chatsRequest,
    shopProductsRequest,
    shopGroupsRequest,
    getAllOrdersClientsRequest,
    getAllOrdersShopRequest
}) => {

    const backdrop = useSelector(state => state.oldApplications.isFetching)
    const allOldApplications = useSelector(state => state.oldApplications.allOldApplications)
    const userID = useSelector(state => state.auth.userId)
    const userKey = useSelector(state => state.auth.userKey)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [contentInfo, setContentInfo] = useState(<></>)

    Font.register({
        family: 'Calibri',
        fonts: [
            {
                src: Calibri,
                fontWeight: 'normal',
            },
            {
                src: CalibriBold,
                fontWeight: 'bold',
            },
            {
                src: CalibriItaly,
                fontStyle: 'Italy'
            },
            {
                src: CalibriIB,
                fontStyle: 'Italy',
                fontWeight: 'bold',
            },
        ],
    });

    const setBackdrop = useCallback((data) => {
        dispatch({ type: 'OLDAPPLICATION/SET_BACKDROP', data })
    }, [dispatch])

console.log(authUser);

    useEffect(() => {
        if (authUser.id === 0) {
            authUserRequest({ id: userID, userKey: userKey })
        }
        if (authUser.mycompany && authUser.mycompany.length > 0) {
            setBackdrop(true)
            myCompanyRequest({ mycompany: authUser.mycompany })
            getCompaniesRequest({ mycompany: [authUser.mycompany[0]] })
            contentsRequest({ mycompany: [authUser.mycompany[0]] })
            chatsRequest({ mycompany: [authUser.mycompany[0]] })
            getAllWagesRequst({ mycompany: [authUser.mycompany[0]] })
            getAllExpensGroupsRequst({ mycompany: [0, authUser.mycompany[0]] })
            getAllExpensesRequest({ mycompany: [authUser.mycompany[0]] })
            getApplicationRequest({ mycompany: [authUser.mycompany[0]] })
            getAllFNO910Requst({ mycompany: [authUser.mycompany[0]] })
            getAddrDeliveryRequest({ mycompany: [authUser.mycompany[0]] })
            getAllFNO913Requst({ mycompany: [authUser.mycompany[0]] })
            pointsRequest({ mycompany: [authUser.mycompany[0]] })
            getOldApplicationRequest({ mycompany: [authUser.mycompany[0]] })
            if (authUser.adminShop) {
                getAllOrdersShopRequest({shop: authUser.mycompany[0]})
            } else {
                getAllOrdersClientsRequest({idUser: authUser.id})
            }            
        }

        if (authUser.shop) {
            shopProductsRequest({idShop: authUser.idShops}, authUser.idGroup)
            shopGroupsRequest({idShop: authUser.idShops}, authUser.idGroup)
        }
    }, [authUser.id, userID, userKey, setBackdrop, chatsRequest, getAllFNO910Requst, pointsRequest, authUserRequest, getAllExpensesRequest, getAllExpensGroupsRequst, getAllFNO913Requst, getAllWagesRequst, myCompanyRequest, authUser.mycompany, getCompaniesRequest, contentsRequest, getApplicationRequest, getAddrDeliveryRequest, getOldApplicationRequest])

    useEffect(() => {
        if (allOldApplications && allOldApplications.length > 0) {
            setBackdrop(false)
        }
    }, [allOldApplications, setBackdrop])

    React.useEffect(() => {
        const openClient = () => {
            setBackdrop(false)
            navigate("client")
        }

        const openCabinet = () => {
            setBackdrop(false)
            navigate("cabinet")
        }

        const openDefault = () => {
            setBackdrop(true)
            setContentInfo(<>
                <Typography color="#07139e" sx={{ m: 'auto', mt: '100px' }} >
                    Загрузка....
                </Typography>
                {/* <Button onClick={exitUser}>ВЫХОД</Button> */}
            </>)
        }

        switch (authUser.account) {
            case 0:
                return openClient();

            case 2:
                return openCabinet();

            default:
                return openDefault();
        }
    }, [authUser.account, navigate, setBackdrop]);

    return <>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdrop}
        >
            <CircularProgress color="primary" />
        </Backdrop>

        {contentInfo}
    </>
}

export default MainApp;
