import * as React from 'react';
import dayjs from 'dayjs';
import TableAllShopOrders from '../../components/TableAllShopOrders';
import { useSelector } from 'react-redux';

const ShopOrders = () => {
    const ordersShopShop = useSelector(state => state.shop.ordersShopShop) // получаем ордер
    const productOrdersShop = useSelector(state => state.shop.productOrdersShop) // получаем товары в ордерах

    const rowOrders = ordersShopShop.map((ord)=>({...ord, allTotal: productOrdersShop.filter((pord)=>pord.idOrders===ord.id).map((bs) => bs.amount * bs.price).reduce(function (a, b) { return a + b }, 0)})) //map((bs) => bs.amount * bs.price).reduce(function (a, b) { return a + b }, 0);
    const dateNow = dayjs(new Date)
    const sortDate = 
    console.log(ordersShopShop);
    console.log(productOrdersShop);
    

    return (
        <div style={{ marginTop: '15px' }}>
            <TableAllShopOrders rowOrders={rowOrders} />
        </div>
    )
}

export default ShopOrders