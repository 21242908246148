import React from 'react';
import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import NavBar from '../Components/NavBar';
import HomeContainer from '../Home/HomeContainer';
//import ArchiveContainer from '../Archive/ArchiveContainer';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DashboardPanel from '../Dashboard/Panels/DashboardPanel';
import AppBarContainer from '../Components/AppBar/AppBarContainer';
import ExpensesContainer from '../Expenses/ExpensesContainer';
import ChatContainer from '../Chat/ChatContainer';
import { useEffect } from 'react';
import ShopOrdersContainer from '../ShopOrders/ShopOrdersContainer';
//import EsfTestContainer from '../ESF/EsfTestContainer';


const Client = ({ authUser, chatsRequest }) => {
    const [value, setValue] = React.useState( authUser.shopOrders? 'orders' : 'recents');
    const [containerInfo, setСontainerInfo] = React.useState(<></>);
    const backdrop = useSelector(state => state.oldApplications.isFetching)
    const chats = useSelector(state => state.chat.chats_id)
    const myCompany = useSelector(state => state.myCompany)
    const navigate = useNavigate()

    useEffect(() => {
        let timer
        if (chats.length > 0) {
            timer = setInterval(() => {
                chatsRequest({ mycompany: [authUser.mycompany[0]] })
            }, 10000);
        }
        // очистка интервала
        return () => clearInterval(timer);
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        const openHome = () => {
            setСontainerInfo(<HomeContainer />)
        }

        const openShopOrders = () => {
            setСontainerInfo(<ShopOrdersContainer />)
        }

        // const openDelete = () => {
        //     setСontainerInfo(<ArchiveContainer />)
        // }

        // const openArchive = () => {
        //     setСontainerInfo(<EsfTestContainer />)
        // }

        const openExpenses = () => {
            setСontainerInfo(<ExpensesContainer myCompany={myCompany} />)
        }

        const openMainChat = () => {
            setСontainerInfo(<div style={{maxWidth: "412px", margin: "0 auto", paddingBottom: 50, paddingTop: 1}} ><ChatContainer /></div>)
        }

        const openDashbord = () => {
            setСontainerInfo(<div style={{ paddingLeft: 15, paddingRight: 15 }} > <DashboardPanel /> </div>)
        }

        const openDefault = () => {
            setСontainerInfo(
                <Typography color="#07139e" sx={{ m: 'auto' }} >
                    Здесь пока нет данных.
                </Typography>)
        }

        switch (value) {

            case "orders":
                return openShopOrders();

            case "recents":
                return openHome();

            case "expenses":
                return openExpenses();

            case "chat":
                return openMainChat();

            // case "delete":
            //     return openDelete();

            case "folder":
                return openDashbord();

            // case "archive":
            //     return openArchive();

            default:
                return openDefault();
        }
    }, [value, myCompany]);

    if (authUser.id === 0) {
        navigate('/')
        return <></>
    } else {
        return <>
            <Box sx={{ textAlign: "center" }}>

                <AppBarContainer />

                <Box sx={{ textAlign: "center", mt: "56px", mb: "56px" }}>
                    {containerInfo}
                </Box>

                <NavBar handleChange={handleChange} value={value} />
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdrop}
            >
                <CircularProgress color="primary" />
            </Backdrop>
        </>
    }
}

export default Client;
